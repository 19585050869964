import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCommissionPlansList() {
  // Use toast
  const toast = useToast()

  const refCommissionPlansListTable = ref(null)
  const refCommissionPlansListItemsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Naam', key: 'name', sortable: false },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  // Table Handlers
  const tableColumnsItems = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Product', key: 'productID', sortable: false },
    { label: 'Start jaar', key: 'yearFrom', sortable: false },
    { label: 'Eind jaar', key: 'yearTo', sortable: false },
    { label: 'Commissie percentage', key: 'commissionPercentage', sortable: false },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  const perPage = ref(10)
  const totalCommissionPlans = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const perPageItems = ref(10)
  const totalCommissionPlansItems = ref(0)
  const currentPageItems = ref(1)
  const perPageOptionsItems = [10, 25, 50, 100]
  const sortByItems = ref('id')
  const isSortDirDescItems = ref(false)
  const showOverlayItems = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCommissionPlansListTable.value ? refCommissionPlansListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCommissionPlans.value,
    }
  })

  const dataMetaItems = computed(() => {
    const localItemsCount = refCommissionPlansListItemsTable.value ? refCommissionPlansListItemsTable.value.localItems.length : 0
    return {
      from: perPageItems.value * (currentPageItems.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageItems.value * (currentPageItems.value - 1) + localItemsCount,
      of: totalCommissionPlansItems.value,
    }
  })

  const refetchCommissionPlansData = () => {
    refCommissionPlansListTable.value.refresh()
  }

  const refetchCommissionPlansItemsData = () => {
    refCommissionPlansListItemsTable.value.refresh()
  }

  watch([currentPageItems, perPageItems], () => {
    refetchCommissionPlansItemsData()
  })

  watch([currentPage, perPage], () => {
    refetchCommissionPlansData()
  })

  const fetchCommissionPlans = (ctx, callback) => {
    store
      .dispatch('app-commission-plan/fetchCommissionPlans', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalCommissionPlans.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de commissie plannen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCommissionPlans,
    tableColumns,
    perPage,
    currentPage,
    totalCommissionPlans,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refCommissionPlansListTable,
    refetchCommissionPlansData,
    showOverlay,

    tableColumnsItems,
    perPageItems,
    currentPageItems,
    totalCommissionPlansItems,
    dataMetaItems,
    perPageOptionsItems,
    sortByItems,
    isSortDirDescItems,
    refCommissionPlansListItemsTable,
    refetchCommissionPlansItemsData,
    showOverlayItems,
  }
}
