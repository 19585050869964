import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCommissionPlans(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}commissionplan`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommissionPlan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}commissionplans/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCommissionPlan(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}commissionplans`, categoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCommissionPlan(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}commissionplans/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCommissionPlan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}commissionplans/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommissionPlanItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}commissionplanitems`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommissionPlanItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}commissionplanitems/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCommissionPlanItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}commissionplanitems`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCommissionPlanItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}commissionplanitems/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCommissionPlanItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}commissionplanitems/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
