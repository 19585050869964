<template>
  <b-sidebar
      id="edit-commission-plan-items-sidebar"
      :visible="isEditCommissionPlanItemsSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-edit-commission-plan-items-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Commissie Plan
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <validation-provider
              #default="validationContext"
              name="product"
              rules="required"
          >
            <b-form-group
                label="Product"
                label-for="product"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="commissionPlanItemData.productID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productsOptions"
                  :reduce="val => val.id"
                  label="name"
                  :clearable="false"
                  input-id="product"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="yearFrom"
              rules="required|integer"
          >
            <b-form-group
                label="Van jaar"
                label-for="yearFrom"
            >
              <b-form-input
                  id="yearFrom"
                  v-model="commissionPlanItemData.yearFrom"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="yearTo"
              rules="required|integer"
          >
            <b-form-group
                label="Tot jaar"
                label-for="yearTo"
            >
              <b-form-input
                  id="yearTo"
                  v-model="commissionPlanItemData.yearTo"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="commissionPercentage"
              rules="required|decimal"
          >
            <b-form-group
                label="Commissie percentage"
                label-for="commissionPercentage"
            >
              <b-form-input
                  id="commissionPercentage"
                  v-model="commissionPlanItemData.commissionPercentage"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-if="$can('update', 'commission-plan')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              <b-spinner
                  small
                  class="mr-1"
                  v-if="showAddSpinner"
              />
              Opslaan
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Annuleren
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer, decimal } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditCommissionPlanItemsSidebarActive',
    event: 'update:is-edit-commission-plan-items-sidebar-active',
  },
  props: {
    isEditCommissionPlanItemsSidebarActive: {
      type: Boolean,
      required: true,
    },
    productsOptions: {
      type: Array,
      required: true,
    },
    commissionPlanItemData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      integer,
      decimal,
      showAddSpinner: false,
    }
  },
  methods: {
    onSubmit() {
      this.showAddSpinner = true
      store.dispatch('app-commission-plan-edit/updateCommissionPlanItem', this.commissionPlanItemData)
        .then(() => {
          this.showAddSpinner = false
          this.$emit('refetch-data')
          this.$emit('update:is-edit-commission-plan-items-sidebar-active', false)
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-locations-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
